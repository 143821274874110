import * as THREE from "three";
import vertex from "../glsl/particles/vertex.vert";
import fragment from "../glsl/particles/fragment.frag";

class Particles {
  constructor(opt) {
    this.scene = opt.scene;
    this.audio = opt.audio;

    this.init();
  }

  init() {
    this.planeParticle = new THREE.PlaneBufferGeometry();

    this.particlesGeometry = new THREE.InstancedBufferGeometry();
    this.particlesGeometry.index = this.planeParticle.index;
    this.particlesGeometry.attributes.position =
      this.planeParticle.attributes.position;
    this.particlesGeometry.attributes.normal =
      this.planeParticle.attributes.normal;
    this.particlesGeometry.attributes.uv = this.planeParticle.attributes.uv;

    this.positions = new Float32Array(500 * 3);
    this.params = new Float32Array(500 * 2);

    for (let i = 0; i < 500; i++) {
      this.i3 = i * 3;

      this.positions[this.i3] = (Math.random() - 0.5) * 10;
      this.positions[this.i3 + 1] = (Math.random() - 0.5) * 10;
      this.positions[this.i3 + 2] = (Math.random() - 0.5) * 10;

      this.params[i + 0] = THREE.MathUtils.randFloatSpread(55); // Offset
      this.params[i + 1] = THREE.MathUtils.randFloat(0.7, 1.3); // Random Scale
      this.params[i + 2] = 1;
    }

    this.particlesGeometry.setAttribute(
      "position",
      new THREE.InstancedBufferAttribute(this.positions, 3, false)
    );
    this.particlesGeometry.setAttribute(
      "aParams",
      new THREE.InstancedBufferAttribute(this.params, 3, false)
    );

    this.particlesMaterial = new THREE.ShaderMaterial({
      vertexShader: vertex,
      fragmentShader: fragment,
      transparent: true,
      blending: THREE.AdditiveBlending,
      uniforms: {
        uTime: { value: 0 },
        uShakeOpacity: { value: 1.0 },
        uOpacity: { value: 0.0 },
        uColor: { value: new THREE.Color(0x008eff) },
      },
    });

    this.particles = new THREE.Points(
      this.particlesGeometry,
      this.particlesMaterial
    );
    this.scene.scene.add(this.particles);
  }

  update(time) {
    this.particlesMaterial.uniforms.uTime.value = time;
    this.particlesMaterial.uniforms.uShakeOpacity.value =
      1.0 + this.audio.notes.bass / 50;
  }
}

export default Particles;
