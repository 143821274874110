import './main.scss'

import * as THREE from 'three'
import { gsap } from 'gsap'
import Scene from '../static/js/Scene'
import Mouse from '../static/js/Mouse'
import InnerSphere from '../static/js/InnerSphere'
import OutSphere from '../static/js/OutSphere'
import Environment from '../static/js/Environment'
import Particles from '../static/js/Particles'
import Audio from '../static/js/Audio'

const scene = new Scene({
    canvas: document.querySelector('.webgl'),
})

const audio = new Audio({
    scene: scene
})

const innerSphere = new InnerSphere({
    scene: scene,
    audio: audio
})

const outSphere = new OutSphere({
    scene: scene,
    audio: audio
})

const environment = new Environment({
    scene: scene,
    audio: audio
})

const particles = new Particles({
    scene: scene,
    audio: audio
})

const mouse = new Mouse({
    scene: scene
})

const groundTl = gsap.timeline({ paused: true })
groundTl.to(environment.groundMaterial, 0.1, { opacity: 0.8 })
groundTl.to(environment.groundMaterial, 0, { opacity: 1 })
groundTl.to(environment.groundMaterial, 0.05, { opacity: 1 })
groundTl.to(environment.groundMaterial, 0, { opacity: 0.8 })
groundTl.to(environment.groundMaterial, 0.1, { opacity: 0.6 })
groundTl.to(environment.groundMaterial, 0, { opacity: 1 })
groundTl.to(environment.groundMaterial, 0.05, { opacity: 1 })
groundTl.to(environment.groundMaterial, 0, { opacity: 0.6 })
groundTl.to(environment.groundMaterial, 0.1, { opacity: 0.4 })
groundTl.to(environment.groundMaterial, 0, { opacity: 1 })
groundTl.to(environment.groundMaterial, 0.05, { opacity: 1 })
groundTl.to(environment.groundMaterial, 0, { opacity: 0.4 })
groundTl.to(environment.groundMaterial, 0.15, { opacity: 0.1 })
groundTl.to(environment.groundMaterial, 0, { opacity: 1 })
groundTl.to(environment.groundMaterial, 0.05, { opacity: 1 })
groundTl.to(environment.groundMaterial, 0.1, { opacity: 0 })
groundTl.to(environment.groundMaterial, 0.1, { opacity: 1 })

document.querySelector('.title-start-container').addEventListener('click', () => {
    mouse.isLoaded = true
    setTimeout(() => {
        audio.setAudio('legendary.mp3')
    }, 150);
    groundTl.play()
    gsap.to(environment.materialShade.uniforms.uAlpha, 1.25, { value: 1, ease: 'Circ.inOut' })
    gsap.to('.webgl-mask', .625, { opacity: 0, ease: 'Circ.inOut' })
    gsap.to('.title-start-container', 0, { pointerEvents: 'none', })
    gsap.to('.title-start-container', 1.25, { transform: 'translate(-50%, -50%) rotate(-90deg)', ease: 'Back.easeInOut' })
    gsap.to('.title-start-container', .625, { opacity: 0, ease: 'Back.easeInOut' })
    gsap.to('.title-start-container .title-letter_1', .825, { top: '-85%', ease: 'Back.easeInOut' })
    gsap.to('.title-start-container .title-letter_2', .825, { left: '85%', ease: 'Back.easeInOut' })
    gsap.to('.title-start-container .title-letter_3', .825, { left: '-85%', ease: 'Back.easeInOut' })
    gsap.to('.title-start-container .title-letter_4', .825, { top: '85%', ease: 'Back.easeInOut' })
    gsap.to('.mobile-click', 0, { pointerEvents: "all" })
    gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 1.25, {
        value: 1.0, ease: 'Circ.inOut', onComplete: function () {
            gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 0, { value: 0.0 })
        }
    })
    gsap.to(outSphere.outSphereMaterial.uniforms.uAlphaLoad, 2.5, { value: 1.0, ease: "Expo.Out" })
    gsap.to(innerSphere.innerSphereMaterial.uniforms.uAlphaLoad, 2.5, { value: 1.0, ease: "Expo.Out" })
    gsap.to(outSphere.outSphereMaterial.uniforms.uAlpha, 0, { value: 1.0, })
    gsap.to(innerSphere.innerSphereMaterial.uniforms.uAlpha, 0, { value: 1.0 })
    gsap.to(globalSphere.scale, 1.25, { x: .95, y: .95, z: .95, ease: "Expo.Out" })
    gsap.to(globalSphere.rotation, 1.5, { x: 0, y: 0, ease: "Power2.Out" })
    gsap.to(environment.bottom.scale, 1.5, { z: 1, y: 1, ease: "Power2.Out" })

    gsap.to(btnMenu, .625, {
        transform: 'translateY(0%)', ease: 'Expo.inOut', delay: .625, onComplete: function () {
            gsap.to(btnMenu, 0, { pointerEvents: 'all' })
        }
    })
    gsap.to('.infos-start .infos-content', .5, { transform: 'translateY(-100%)', ease: 'power3.out' })
    gsap.to('.title-container .title-letter', .625, {
        top: '0', stagger: { from: 'start', each: 0.035 }, ease: 'Expo.inOut', delay: .625, onComplete: function () {
            gsap.to(title, 0, { pointerEvents: 'all' })
        }
    })
})

document.addEventListener('contextmenu', e => {
    e.preventDefault()
})

// Switch colors
let switchColor = 'blue'
const app = document.querySelector('.app')

function hideLoadingCursorAnim() {
    gsap.to('.circle-in-loading svg circle', 1.5, { strokeDashoffset: '120rem', ease: "power2.out" })
    gsap.to('.indication-loading_mask', 1.5, { clipPath: 'inset(0% 100% 0% 0%)', ease: "power2.inOut" })
    gsap.to('.circle-in svg circle', .75, { strokeDashoffset: '120rem', ease: "Power2.inOut" })
    gsap.to('.circle-out svg circle', .75, { strokeDashoffset: '0rem', ease: "Power2.inOut" })
    gsap.to('.circle-out', .375, { transform: 'translate(-50%, -50%) scale(1)', ease: "Power2.inOut" })
    gsap.to('.cursor-circle', .375, { transform: 'translate(-50%, -50%) rotate(0deg)', ease: "Power2.inOut" })
    gsap.to('.dot-wrapper .dot', .75, { opacity: '1', transform: 'scale(1)', stagger: { from: 'center', each: 0.075 }, ease: "Power2.inOut" })
    gsap.to('.indication-wrapper', .375, { opacity: '0', transform: 'translate(-50%, -50%) rotate(-90deg) scale(0.4)', ease: "Power2.inOut" })
    gsap.to('.indication-loading-wrapper', .375, { opacity: '0', transform: 'translate(-50%, -50%) rotate(-90deg) scale(0.4)', ease: "Power2.inOut" })
    gsap.to('.indication-loading', .3, { transform: 'translate(-50%, -200%)', ease: 'power3.out' })
    gsap.to('.indication-loading', 0, { transform: 'translate(-50%, 100%)', delay: .35 })
    gsap.to('.indication-hold', .3, { transform: 'translate(-50%, -50%)', ease: 'power3.out' })
    document.body.style.cursor = "initial"
}

function onMouseDown() {
    if (!mouse.isHovering && !mouse.isIntersecting && mouse.isLoaded) {
        mouse.isDown = true
        clearTimeout(this.downTimerColor)
        this.downTimerColor = setTimeout(() => {
            hideLoadingCursorAnim()
            gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 1, {
                value: 1.0, ease: 'Circ.inOut', onComplete: function () {
                    gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 0, { value: 0.0 })
                }
            })
            if (switchColor == 'blue') {
                gsap.to(app, .75, {
                    filter: 'hue-rotate(145deg)', ease: 'Circ.inOut', onComplete: function () {
                        switchColor = 'red'
                    }
                })
            }

            if (switchColor == 'red') {
                gsap.to(app, .75, {
                    filter: 'hue-rotate(290deg)', ease: 'Circ.inOut', onComplete: function () {
                        switchColor = 'green'
                    }
                })
            }

            if (switchColor == 'green') {
                gsap.to(app, .75, {
                    filter: 'hue-rotate(360deg)', ease: 'Circ.inOut', onComplete: function () {
                        app.style.filter = "hue-rotate(0deg)"
                        switchColor = 'blue'
                    }
                })
            }
        }, 1500)

        clearTimeout(this.downTimerCursor)
        this.downTimerCursor = setTimeout(() => {
            mouse.isMooving = false
            if (!mouse.isIntersecting) {
                gsap.to('.circle-in-loading svg circle', 1.5, { strokeDashoffset: '0rem', ease: "Power2.in" })
                gsap.to('.indication-loading_mask', 1.5, { clipPath: 'inset(0% 0% 0% 0%)', ease: "power2.inOut" })
                gsap.to('.circle-in svg circle', .75, { strokeDashoffset: '0rem', ease: "Power2.inOut" })
                gsap.to('.circle-out svg circle', .75, { strokeDashoffset: '120rem', ease: "Power2.inOut" })
                gsap.to('.circle-out', .375, { transform: 'translate(-50%, -50%) scale(.25)', ease: "Power2.inOut" })
                gsap.to('.cursor-circle', .375, { transform: 'translate(-50%, -50%) rotate(90deg)', ease: "Power2.inOut" })
                gsap.to('.dot-wrapper .dot', .75, { opacity: '0', transform: 'scale(0)', stagger: { from: 'center', each: 0.075 }, ease: "Power2.inOut" })
                gsap.to('.indication-wrapper', .375, { opacity: '0', transform: 'translate(-50%, -50%) rotate(-90deg) scale(.4)', ease: "Power2.inOut" })
                gsap.to('.indication-loading-wrapper', .375, { opacity: '1', transform: 'translate(-50%, -50%) rotate(-90deg) scale(1)', ease: "Power2.inOut" })
                gsap.to('.indication-loading', 0, { transform: 'translate(-50%, 50%)' })
                gsap.to('.indication-loading', .3, { transform: 'translate(-50%, -50%)', ease: 'power3.out' })
                gsap.to('.indication-hold', .3, { transform: 'translate(-50%, -150%)', ease: 'power3.out' })
                gsap.to('.indication-hold', 0, { transform: 'translate(-50%, 50%)', delay: .35 })
                document.body.style.cursor = "none"
            }
        }, 250)
    }
}
function onMouseDownMobile() {
    if (mouse.isLoaded) {
        mouse.isDown = true
        clearTimeout(this.downTimerColor)
        this.downTimerColor = setTimeout(() => {
            gsap.to(scene.displacementPass.material.uniforms.uDispFactor, .75, {
                value: 1.0, ease: 'Circ.inOut', onComplete: function () {
                    gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 0, { value: 0.0 })
                }
            })
            if (switchColor == 'blue') {
                gsap.to(app, .75, {
                    filter: 'hue-rotate(145deg)', ease: 'Circ.inOut', onComplete: function () {
                        switchColor = 'red'
                    }
                })
            }

            if (switchColor == 'red') {
                gsap.to(app, .75, {
                    filter: 'hue-rotate(290deg)', ease: 'Circ.inOut', onComplete: function () {
                        switchColor = 'green'
                    }
                })
            }

            if (switchColor == 'green') {
                gsap.to(app, .75, {
                    filter: 'hue-rotate(360deg)', ease: 'Circ.inOut', onComplete: function () {
                        app.style.filter = "hue-rotate(0deg)"
                        switchColor = 'blue'
                    }
                })
            }
        }, 250)
    }
}

document.addEventListener('mousedown', onMouseDown, false)
document.addEventListener('touchstart', onMouseDownMobile, false)

function onMouseUp() {
    clearTimeout(this.downTimerColor)
    clearTimeout(this.downTimerCursor)
    mouse.isMooving = true
    mouse.isDown = false
    if (!mouse.isIntersecting) {
        hideLoadingCursorAnim()
    }
}
function onMouseUpMobile() {
    clearTimeout(this.downTimerColor)
    clearTimeout(this.downTimerCursor)
    mouse.isDown = false
}

document.addEventListener('mouseup', onMouseUp, false)
document.addEventListener('touchend', onMouseUpMobile, false)

// Audio buttons
audio.buttons.forEach((button, index) => {
    button.addEventListener('click', () => {
        if (index == 0 || index == 3) {
            if (switchColor == 'red') {
                gsap.to(app, .75, {
                    filter: 'hue-rotate(0deg)', ease: 'Circ.inOut', onComplete: function () {
                        switchColor = 'blue'
                    }
                })
                gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 1, {
                    value: 1.0, ease: 'Circ.inOut', onComplete: function () {
                        gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 0, { value: 0.0 })
                    }
                })
            }
            if (switchColor == 'green') {
                gsap.to(app, .75, {
                    filter: 'hue-rotate(360deg)', ease: 'Circ.inOut', onComplete: function () {
                        app.style.filter = "hue-rotate(0deg)"
                        switchColor = 'blue'
                    }
                })
                gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 1, {
                    value: 1.0, ease: 'Circ.inOut', onComplete: function () {
                        gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 0, { value: 0.0 })
                    }
                })
            }
        }
        if (index == 1 || index == 4) {
            if (switchColor == 'green') {
                gsap.to(app, .75, {
                    filter: 'hue-rotate(145deg)', ease: 'Circ.inOut', onComplete: function () {
                        switchColor = 'red'
                    }
                })
                gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 1, {
                    value: 1.0, ease: 'Circ.inOut', onComplete: function () {
                        gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 0, { value: 0.0 })
                    }
                })
            }
            if (switchColor == 'blue') {
                app.style.filter = "hue-rotate(0deg)"
                gsap.to(app, .75, {
                    filter: 'hue-rotate(145deg)', ease: 'Circ.inOut', onComplete: function () {
                        switchColor = 'red'
                    }
                })
                gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 1, {
                    value: 1.0, ease: 'Circ.inOut', onComplete: function () {
                        gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 0, { value: 0.0 })
                    }
                })
            }
        }
        if (index == 2 || index == 5) {
            if (switchColor == 'red') {
                gsap.to(app, .75, {
                    filter: 'hue-rotate(290deg)', ease: 'Circ.inOut', onComplete: function () {
                        switchColor = 'green'
                    }
                })
                gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 1, {
                    value: 1.0, ease: 'Circ.inOut', onComplete: function () {
                        gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 0, { value: 0.0 })
                    }
                })
            }

            if (switchColor == 'blue') {
                app.style.filter = "hue-rotate(360deg)"
                gsap.to(app, .75, {
                    filter: 'hue-rotate(290deg)', ease: 'Circ.inOut', onComplete: function () {
                        switchColor = 'green'
                    }
                })
                gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 1, {
                    value: 1.0, ease: 'Circ.inOut', onComplete: function () {
                        gsap.to(scene.displacementPass.material.uniforms.uDispFactor, 0, { value: 0.0 })
                    }
                })
            }
        }
        gsap.to('label', 0.375, { color: "#00cbff", ease: "Power2.inOut" })

        const lastActive = document.querySelector('.active');
        if (lastActive) lastActive.classList.remove('active');

        button.classList.add('active')

        closeMenu()
    })
})

document.querySelector('.file-input').addEventListener('change', () => {
    gsap.to('label', 0.375, { color: "#008eff", ease: "Power2.inOut" })
    audio.buttons.forEach((button) => {
        button.classList.remove('active')
    })
    closeMenu()
})
document.querySelector('.file-input').addEventListener('click', () => {
    gsap.to('.circle-out', .375, { transform: 'translate(-50%, -50%) scale(0)', ease: "Power2.inOut" })
})

// Pause on sphere
document.addEventListener('click', () => {
    if (mouse.isIntersecting && !mouse.isHovering && (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !window.matchMedia('(max-width: 1024px)').matches)) {
        if (!audio.isPaused) {
            gsap.to('.indication-pause', .35, { transform: 'translate(-50%, -150%)', ease: 'power3.out' })
            gsap.to('.indication-play', .35, { transform: 'translate(-50%, -50%)', ease: 'power3.out' })
            gsap.to(audio.listener.gain.gain, .35, {
                value: 0.0, ease: 'Circ.out', onComplete: function () {
                    audio.sound.pause()
                    audio.isPaused = true
                }
            })
        }
        else {
            if (!menuIsOpen) {
                gsap.to(audio.listener.gain.gain, .35, { value: 1.0, ease: 'Circ.out' })
            }
            else {
                gsap.to(audio.listener.gain.gain, .35, { value: 0.5, ease: 'Circ.out' })
            }
            gsap.to('.indication-pause', 0, { transform: 'translate(-50%, 50%)' })
            gsap.to('.indication-pause', .35, { transform: 'translate(-50%, -50%)', ease: 'power3.out' })
            gsap.to('.indication-play', .35, { transform: 'translate(-50%, -150%)', ease: 'power3.out' })
            gsap.to('.indication-play', 0, { transform: 'translate(-50%, 50%)', delay: .35 })
            audio.sound.play()
            audio.isPaused = false
        }
    }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.matchMedia('(max-width: 1024px)').matches) {
        if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
            }
        }
    }
})

document.querySelector('.mobile-click').addEventListener('touchstart', () => {
    if (!audio.isPaused) {
        gsap.to('.indication-pause', .35, { transform: 'translate(-50%, -150%)', ease: 'power3.out' })
        gsap.to('.indication-play', .35, { transform: 'translate(-50%, -50%)', ease: 'power3.out' })
        gsap.to(audio.listener.gain.gain, .35, {
            value: 0.0, ease: 'Circ.out', onComplete: function () {
                audio.sound.pause()
                audio.isPaused = true
            }
        })
    }
    else {
        if (!menuIsOpen) {
            gsap.to(audio.listener.gain.gain, .35, { value: 1.0, ease: 'Circ.out' })
        }
        else {
            gsap.to(audio.listener.gain.gain, .35, { value: 0.5, ease: 'Circ.out' })
        }
        gsap.to('.indication-pause', 0, { transform: 'translate(-50%, 50%)' })
        gsap.to('.indication-pause', .35, { transform: 'translate(-50%, -50%)', ease: 'power3.out' })
        gsap.to('.indication-play', .35, { transform: 'translate(-50%, -150%)', ease: 'power3.out' })
        gsap.to('.indication-play', 0, { transform: 'translate(-50%, 50%)', delay: .35 })
        audio.sound.play()
        audio.isPaused = false
    }
})

// Title
const title = document.querySelector('.title-container')
title.addEventListener('click', () => {
    mouse.hideHoverCursorAnim()
})


// Menu
const btnMenu = document.querySelector('.btn-menu')
const btnClose = document.querySelector('.btn-close')
let menuIsOpen = false

btnMenu.addEventListener('click', () => {
    gsap.to(btnMenu, .5, { transform: 'translateY(-100%)', ease: 'power3.out' })
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.matchMedia('(max-width: 823px)').matches) {
        gsap.to(btnMenu, 0, {
            transform: 'translateY(100%)', delay: .65, onComplete: function () {
                gsap.to('.btn-wrapper_close', 0, { pointerEvents: 'all' })
                gsap.to('.musics-wrapper', 0, { pointerEvents: 'all', })
                gsap.to('.btn-wrapper_menu', 0, { pointerEvents: 'none' })
                gsap.to(title, 0, { pointerEvents: 'none' })
            }
        })
    }
    else {
        gsap.to(btnMenu, 0, {
            transform: 'translateY(100%)', delay: .8, onComplete: function () {
                gsap.to('.btn-wrapper_close', 0, { pointerEvents: 'all' })
                gsap.to('.musics-wrapper', 0, { pointerEvents: 'all', })
                gsap.to('.btn-wrapper_menu', 0, { pointerEvents: 'none' })
                gsap.to(title, 0, { pointerEvents: 'none' })
            }
        })
    }
    gsap.to(btnClose, .5, { transform: 'translateY(0)', ease: 'power3.out' })

    gsap.to('.translate-animation', 0, { transform: 'translateY(100%)' })
    gsap.to('.translate-animation', .5, { transform: 'translateY(0)', stagger: 0.035, ease: 'power2.out', delay: .5 })
    gsap.to('.infos .infos-content', 0, { transform: 'translateY(100%)' })
    gsap.to('.infos .infos-content', .5, { transform: 'translateY(0)', stagger: 0.035, ease: 'power2.out', delay: .9 })
    gsap.to('.credits-content', 0, { transform: 'translateY(100%)' })
    gsap.to('.credits-content', .5, { pointerEvents: 'all', transform: 'translateY(0)', stagger: 0.035, ease: 'power2.out', delay: .9 })



    gsap.to('.title-container .title-letter', 0, { top: '0' })
    gsap.to('.title-container .title-letter', .5, { top: '100%', stagger: { from: 'end', each: 0.035 }, ease: 'Back.easeInOut' })

    gsap.to('.canvas-container', 1, { filter: 'hue-rotate(-45deg) sepia(0.5) contrast(1.2)', ease: 'Circ.out' })
    gsap.to(particles.particlesMaterial.uniforms.uOpacity, 1, { value: 0, ease: 'Circ.out' })
    gsap.to(scene.camera.position, 1.5, { z: 4.1, ease: 'Power3.easeInOut' })
    gsap.to(environment.light.position, 1.5, { z: 3.3, ease: 'Power3.easeInOut' })

    gsap.to(audio.listener.gain.gain, 1.25, { value: .5, ease: 'Circ.out' })

    menuIsOpen = true
    mouse.hideHoverCursorAnim()
})

btnClose.addEventListener('click', () => {
    closeMenu()
})

function closeMenu() {
    gsap.to(btnMenu, .5, { transform: 'translateY(0)', ease: 'power3.out' })
    gsap.to(btnClose, .5, { transform: 'translateY(-100%)', ease: 'power3.out' })
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.matchMedia('(max-width: 823px)').matches) {
        gsap.to(btnClose, 0, {
            transform: 'translateY(100%)', delay: .65, onComplete: function () {
                gsap.to('.btn-wrapper_close', 0, { pointerEvents: 'none' })
                gsap.to('.musics-wrapper', 0, { pointerEvents: 'none', })
                gsap.to('.btn-wrapper_menu', 0, { pointerEvents: 'all' })
                gsap.to(title, 0, { pointerEvents: 'all' })
            }
        })
    }
    else {
        gsap.to(btnClose, 0, {
            transform: 'translateY(100%)', delay: .8, onComplete: function () {
                gsap.to('.btn-wrapper_close', 0, { pointerEvents: 'none' })
                gsap.to('.musics-wrapper', 0, { pointerEvents: 'none', })
                gsap.to('.btn-wrapper_menu', 0, { pointerEvents: 'all' })
                gsap.to(title, 0, { pointerEvents: 'all' })
            }
        })
    }

    gsap.to('.translate-animation', .5, { transform: 'translateY(-100%)', stagger: 0.035, ease: 'Back.easeInOut' })
    gsap.to('.infos .infos-content', .5, { transform: 'translateY(-100%)', stagger: 0.035, ease: 'Back.easeInOut' })
    gsap.to('.credits-content', .5, { pointerEvents: 'none', transform: 'translateY(-100%)', stagger: 0.035, ease: 'Back.easeInOut' })



    gsap.to('.title-container .title-letter', 0, { top: '-100%' })
    gsap.to('.title-container .title-letter', .5, { top: '0', stagger: { from: 'start', each: 0.035 }, ease: 'power2.out', delay: .35 })


    gsap.to('.canvas-container', 1.25, { filter: 'hue-rotate(0deg) sepia(0) contrast(1)', ease: 'Back.easeInOut' })
    gsap.to(particles.particlesMaterial.uniforms.uOpacity, 1.25, { value: 1, ease: 'Back.easeInOut' })
    gsap.to(scene.camera.position, 1.25, { z: 3, ease: 'Power3.easeInOut' })
    gsap.to(environment.light.position, 1.25, { z: 2.2, ease: 'Power3.easeInOut' })

    gsap.to(audio.listener.gain.gain, 1.25, { value: 1, ease: 'Circ.out' })

    menuIsOpen = false
    mouse.hideHoverCursorAnim()
}

// Sphere group
const sphere = new THREE.Group()
sphere.add(outSphere.outSphereMesh, outSphere.outSphereRecasterMesh, innerSphere.innerSphereMesh, audio.sound)
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.matchMedia('(max-width: 823px)').matches && window.matchMedia('(orientation: portrait)').matches) {
    sphere.scale.set(.75, .75, .75)
}
else {
    sphere.scale.set(.95, .95, .95)
}

const globalSphere = new THREE.Group()
globalSphere.add(sphere)
globalSphere.rotation.set(- Math.PI / 2, - Math.PI, 0)
globalSphere.scale.set(0, 0, 0)
scene.scene.add(globalSphere)

scene.displacementPass.material.uniforms.uDisplacement.value = environment.textureLoader.load('../assets/img/displacement/displacement.jpg')

function raf() {
    const elapsedTime = scene.clock.getElapsedTime()

    // Update audio
    audio.update()

    // Update spheres
    innerSphere.update(elapsedTime)
    outSphere.update(elapsedTime)

    // Update group sphere
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.matchMedia('(max-width: 823px)').matches && window.matchMedia('(orientation: portrait)').matches) {
        sphere.scale.x = .75 + audio.notes.medium / 1350
        sphere.scale.y = .75 + audio.notes.medium / 1350
        sphere.scale.z = .75 + audio.notes.medium / 1350
    }
    else {
        sphere.scale.x = .95 + audio.notes.medium / 1350
        sphere.scale.y = .95 + audio.notes.medium / 1350
        sphere.scale.z = .95 + audio.notes.medium / 1350
    }

    // Update environment
    environment.update(elapsedTime)

    // Update mouse
    mouse.update()

    mouse.raycaster.setFromCamera(mouse.mouse, scene.camera)
    const intersect = mouse.raycaster.intersectObject(outSphere.outSphereRecasterMesh)

    if (intersect.length) {
        mouse.isIntersecting = true
    }
    else {
        mouse.isIntersecting = false
    }

    // Update particles
    particles.update(elapsedTime)

    // Update camera
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !window.matchMedia('(max-width: 1024px)').matches) {
        scene.camera.position.y += 0.03 * (mouse.target.y * 23.9 - scene.camera.position.y)
        scene.camera.position.x += 0.03 * (mouse.target.x * 23.9 - scene.camera.position.x)
    }
    else {
        if (scene.camera.position.y > 1) {
            scene.camera.position.y = 1
        }
        if (scene.camera.position.y < -1) {
            scene.camera.position.y = -1
        }
        else {
            scene.camera.position.y += 0.03 * (mouse.target.y * 23.9 - scene.camera.position.y)
        }

        if (scene.camera.position.x > 1.2) {
            scene.camera.position.x = 1.2
        }
        if (scene.camera.position.x < -1.2) {
            scene.camera.position.x = -1.2
        }
        else {
            scene.camera.position.x += 0.03 * (mouse.target.x * 23.9 - scene.camera.position.x)
        }
    }

    audio.sound.position.y = - mouse.mouse.y
    audio.sound.position.x = - mouse.mouse.x
    scene.camera.lookAt(new THREE.Vector3(0, 0, 0))

    // // Update composer
    scene.displacementPass.material.uniforms.uTime.value = elapsedTime

    scene.unrealBloomPass.strength = 2.5 + audio.notes.lowAcute / 22.5
    scene.unrealBloomPass.radius = audio.notes.lowAcute / 150

    // Update postprocessing
    scene.composer.render()

    window.requestAnimationFrame(raf)
}

window.requestAnimationFrame(raf)

document.addEventListener('DOMContentLoaded', () => {
    gsap.to('.app', 3, { opacity: '1', ease: 'Power2.inOut' })
    gsap.to('.loader-wrapper', .625, { transform: 'translateY(0%)', ease: 'Expo.inOut' })
    gsap.to('.infos-start .infos-wrapper .infos-content', .625, { transform: 'translateY(0%)', ease: 'Expo.inOut', delay: .3125 })
    gsap.to('.loader-line-wrapper', .625, { opacity: '1', ease: 'Expo.inOut', delay: .3125 })
})

window.addEventListener('load', () => {
    setTimeout(() => {
        gsap.to('.loader-line-wrapper', .625, { opacity: '0', ease: 'Expo.inOut' })
        gsap.to('.infos-start .infos-wrapper .infos-content', .625, { transform: 'translateY(-100%)', ease: 'Expo.inOut' })
        gsap.to('.loader-wrapper', .625, {
            transform: 'translateY(-100%)', ease: 'Expo.inOut', onComplete: function () {
                gsap.to('.title-start-container', 0, { pointerEvents: 'all' })
            }
        })
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !window.matchMedia('(max-width: 823px)').matches) {
            gsap.to('.title-start-container', 1.15, { transform: 'translate(-50%, -50%) scale(0.6) rotate(0deg)', ease: 'Power3.Out', delay: .1 })
        }
        else {
            gsap.to('.title-start-container', 1.15, { transform: 'translate(-50%, -50%) scale(1) rotate(0deg)', ease: 'Power3.Out', delay: .1 })
        }
        gsap.to('.title-start-container', 1.15, { opacity: 1, ease: 'Power3.Out', delay: .1 })
        gsap.to('.title-start-container .title-letter_1', 1.15, { top: '0', ease: 'Power3.InOut', delay: .1 })
        gsap.to('.title-start-container .title-letter_2', 1.15, { left: '0', ease: 'Power3.InOut', delay: .1 })
        gsap.to('.title-start-container .title-letter_3', 1.15, { left: '0', ease: 'Power3.InOut', delay: .1 })
        gsap.to('.title-start-container .title-letter_4', 1.15, { top: '0', ease: 'Power3.InOut', delay: .1 })
        gsap.to(particles.particlesMaterial.uniforms.uOpacity, 1.15, { value: 1, ease: 'Power3.InOut', delay: .1 })
    }, 1000)
})
