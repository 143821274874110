import * as THREE from 'three'
import { Reflector } from 'three/examples/jsm/objects/Reflector'
import vertex from '../glsl/shadeEnvironment/vertex.vert'
import fragment from '../glsl/shadeEnvironment/fragment.frag'

class Environment {
   constructor(opt) {
      this.scene = opt.scene
      this.audio = opt.audio

      this.addTextures()
      this.init()
   }

   init() {
      this.geometry = new THREE.PlaneBufferGeometry(11, 11)

      this.groundMaterial = new THREE.MeshStandardMaterial({
         map: this.groundBaseTexture,
         roughnessMap: this.groundRoughnessTexture,
         metalnessMap: this.groundMetalnessTexture,
         aoMap: this.groundAoTexture,
         transparent: true,
         opacity: 0,
         metalness: 1.0,
         roughness: 0.0,
         side: THREE.DoubleSide,
         depthWrite: false,
         blending: THREE.AdditiveBlending,
      })

      this.materialShade = new THREE.ShaderMaterial({
         vertexShader: vertex,
         fragmentShader: fragment,
         uniforms: {
            uTime: { value: 0 },
            uTexture: { value: this.groundBaseTexture },
            uColor: { value: new THREE.Color(0x008EFF) },
            uAlpha: { value: 0 },
            uShakeOpacity: { value: 0 },
         },
         transparent: true,
         side: THREE.DoubleSide,
         depthWrite: false,
         blending: THREE.AdditiveBlending,
      })

      this.groundMirror = new Reflector(this.geometry, {
         clipBias: 0.3,
         textureWidth: window.innerWidth * window.devicePixelRatio,
         textureHeight: window.innerHeight * window.devicePixelRatio,
         color: 0x060C1A
      })
      this.groundMirror.position.set(0, -1.2, -2)
      this.groundMirror.rotateX(- Math.PI / 2)

      this.bottom = new THREE.Mesh(this.geometry, this.groundMaterial)
      this.bottom.position.set(0, -1.19, -2)
      this.bottom.rotateX(- Math.PI / 2)

      this.bottomShade = this.bottom.clone()
      this.bottomShade.position.set(0, -1.19, -2)
      this.bottomShade.material = this.materialShade

      this.left = new THREE.Mesh(this.geometry, this.groundMaterial)
      this.left.position.set(-5.5, 4.3, -2)
      this.left.rotateY(- Math.PI / 2)

      this.leftShade = this.left.clone()
      this.leftShade.position.set(-5.49, 4.3, -2)
      this.leftShade.material = this.materialShade

      this.right = new THREE.Mesh(this.geometry, this.groundMaterial)
      this.right.position.set(5.5, 4.3, -2)
      this.right.rotateY(- Math.PI / 2)

      this.rightShade = this.right.clone()
      this.rightShade.position.set(5.49, 4.3, -2)
      this.rightShade.material = this.materialShade

      this.group = new THREE.Group()
      this.group.add(this.bottom, this.bottomShade, this.left, this.leftShade, this.right, this.rightShade)

      if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !window.matchMedia('(max-width: 1024px)').matches) this.group.add(this.groundMirror)

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.matchMedia('(max-width: 812px)').matches && window.matchMedia('(orientation: portrait)').matches) {
         this.group.position.y = 0
      }
      else {
         this.group.position.y = -.45
      }

      this.light = new THREE.SpotLight(0x0025FF, 2, 50, 90, 1, 2)
      this.light.position.z = 2.2

      this.scene.scene.add(this.group, this.light)
   }

   addTextures() {
      this.textureLoader = new THREE.TextureLoader()
      this.groundBaseTexture = this.textureLoader.load('../assets/img/ground/ground_basecolor.jpg')
      this.groundBaseTexture.wrapS = this.groundBaseTexture.wrapT = THREE.MirroredRepeatWrapping

      this.groundNormalScaleTexture = this.textureLoader.load('../assets/img/ground/ground_height.png')
      this.groundNormalScaleTexture.rotation = Math.PI * 0.25
      this.groundNormalScaleTexture.center.x = 0.5
      this.groundNormalScaleTexture.center.y = 0.5
      this.groundNormalScaleTexture.wrapS = this.groundNormalScaleTexture.wrapT = THREE.MirroredRepeatWrapping

      this.groundRoughnessTexture = this.textureLoader.load('../assets/img/ground/ground_roughness.jpg')
      this.groundRoughnessTexture.rotation = Math.PI * 0.25
      this.groundRoughnessTexture.center.x = 0.5
      this.groundRoughnessTexture.center.y = 0.5
      this.groundRoughnessTexture.wrapS = this.groundRoughnessTexture.wrapT = THREE.MirroredRepeatWrapping

      this.groundMetalnessTexture = this.textureLoader.load('../assets/img/ground/ground_metallic.png')
      this.groundMetalnessTexture.rotation = Math.PI * 0.25
      this.groundMetalnessTexture.center.x = 0.5
      this.groundMetalnessTexture.center.y = 0.5
      this.groundMetalnessTexture.wrapS = this.groundMetalnessTexture.wrapT = THREE.MirroredRepeatWrapping

      this.groundAoTexture = this.textureLoader.load('../assets/img/ground/ground_ambientocclusion.png')
      this.groundAoTexture.rotation = Math.PI * 0.25
      this.groundAoTexture.center.x = 0.5
      this.groundAoTexture.center.y = 0.5
      this.groundAoTexture.wrapS = this.groundAoTexture.wrapT = THREE.MirroredRepeatWrapping
   }

   update(time) {
      this.materialShade.uniforms.uTime.value = time
      this.materialShade.uniforms.uShakeOpacity.value = this.audio.notes.lowBass / 35
   }
}

export default Environment