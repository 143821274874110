import * as THREE from 'three'
import { gsap } from 'gsap'

class Audio {
   constructor(opt) {
      this.scene = opt.scene

      this.init()
   }

   init() {
      // create an AudioListener and add it to the camera
      this.listener = new THREE.AudioListener()

      // create a global audio source
      this.sound = new THREE.PositionalAudio(this.listener)
      this.sound.setLoop(true);
      this.sound.setVolume(1.0);

      this.scene.camera.add(this.listener)

      // load a sound and set it as the Audio object's buffer
      this.audioLoader = new THREE.AudioLoader()

      this.analyser = new THREE.AudioAnalyser(this.sound, 2048)

      this.notes = {
         lowBass: 0,
         bass: 0,
         highBass: 0,
         lowMedium: 0,
         medium: 0,
         highMedium: 0,
         lowAcute: 0,
         acute: 0,
         highAcute: 0,
      }

      this.isPlaying = null
      this.isPaused = false
      this.buffers = []
      this.buttons = document.querySelectorAll('.btn-music')

      this.buttons.forEach(button => {
         const filename = button.dataset.filename

         this.audioLoader.load(`../assets/music/${filename}`, (buffer) => {
            this.buffers.push({ filename, buffer })
         })
         button.addEventListener('click', () => {
            if (this.isPaused) {
               gsap.to(this.listener.gain.gain, .35, { value: 1.0, ease: 'Circ.out' })
               this.sound.play()
               this.isPaused = false
            }
            if (!this.isPlaying) {
               this.setAudio(button.dataset.filename);
            } else {
               if (this.isPlaying === filename) gsap.to(this.listener.gain.gain, 1.25, { value: 1.0, ease: 'Circ.out' })
               if (!(this.isPlaying === filename)) {
                  this.setAudio(filename)
                  gsap.to(this.listener.gain.gain, 0, {
                     value: 0.0, ease: 'Circ.out', onComplete: () => {
                        gsap.to(this.listener.gain.gain, 1.25, { value: 1.0, ease: 'Circ.out' })
                     }
                  })
               }
            }
         })
      })

      document.querySelector('.file-input').addEventListener('change', async (e) => {
         const file = e.target.files[0];
         if (file) {
            const arrayBuffer = await file.arrayBuffer();
            const context = THREE.AudioContext.getContext();
            context.decodeAudioData(arrayBuffer, (audioBuffer) => {
               if (this.sound.isPlaying) this.sound.stop()

               if (this.isPaused) {
                  gsap.to(this.listener.gain.gain, .35, { value: 1.0, ease: 'Circ.out' })
                  this.isPaused = false
                  this.sound.stop()
               }

               this.sound.setBuffer(audioBuffer)
               this.isPlaying = e.target.files[0].name;
               this.sound.play()
               e.target.value = null;
            })
         }
      });
   }

   setAudio(filename) {
      if (this.sound.isPlaying) this.sound.stop()
      this.isPlaying = filename;


      for (let i = 0; i < this.buffers.length; i++) {
         if (this.buffers[i].filename === filename) {
            this.sound.setBuffer(this.buffers[i].buffer)
            this.sound.play()
         }
      }
   }

   update() {
      // Analyser & notes frequency
      this.analyser.getFrequencyData();

      this.notes.lowBass = this.analyser.data[0]
      this.notes.bass = this.analyser.data[8]
      this.notes.highBass = this.analyser.data[16]
      this.notes.lowMedium = this.analyser.data[32]
      this.notes.medium = this.analyser.data[64]
      this.notes.highMedium = this.analyser.data[128]
      this.notes.lowAcute = this.analyser.data[256]
      this.notes.acute = this.analyser.data[512]
      this.notes.highAcute = this.analyser.data[1023]
   }
}

export default Audio