import * as THREE from 'three'
import vertex from '../glsl/innerSphere/vertex.vert'
import fragment from '../glsl/outSphere/fragment.frag'

class OutSphere {
   constructor(opt) {
      this.scene = opt.scene
      this.audio = opt.audio

      this.init()
   }

   init() {
      this.outSphereGeometry = new THREE.SphereBufferGeometry(1, 120, 120)
      this.outSphereMaterial = new THREE.ShaderMaterial({
         vertexShader: vertex,
         fragmentShader: fragment,
         uniforms: {
            uTime: { value: 0 },
            uColor: { value: new THREE.Color(0x008EFF) },
            uColor2: { value: new THREE.Color(0x00CBFF) },
            uColor3: { value: new THREE.Color(0x52FBDD) },

            uRepeatSpeed: { value: 0 },
            uDeformedIntensity: { value: 20 },

            uAlpha: { value: 0 },
            uAlphaLoad: { value: 2 },
            uSize: { value: 1 },
         },

         transparent: true,
         blending: THREE.AdditiveBlending,
      })

      this.outSphereRecasterMaterial = new THREE.MeshBasicMaterial({
         transparent: true,
         opacity: 0,
      })

      this.outSphereMesh = new THREE.Points(this.outSphereGeometry, this.outSphereMaterial)
      this.outSphereRecasterMesh = new THREE.Mesh(this.outSphereGeometry, this.outSphereRecasterMaterial)
      this.outSphereRecasterMesh.scale.set(1.1, 1.1, 1.1)
   }

   update(time) {
      this.outSphereMaterial.uniforms.uTime.value = time
      this.outSphereMaterial.uniforms.uDeformedIntensity.value = 20 - this.audio.notes.lowBass / 14.5
      this.outSphereMaterial.uniforms.uRepeatSpeed.value = this.audio.notes.lowBass / 250

      this.outSphereMesh.rotation.y = time / 2
      this.outSphereMesh.scale.x = 1 + this.audio.notes.lowBass / 500
      this.outSphereMesh.scale.y = 1 + this.audio.notes.lowBass / 500
      this.outSphereMesh.scale.z = 1 + this.audio.notes.lowBass / 500
      this.outSphereRecasterMesh.scale.x = 1.1 + this.audio.notes.lowBass / 500
      this.outSphereRecasterMesh.scale.y = 1.1 + this.audio.notes.lowBass / 500
      this.outSphereRecasterMesh.scale.z = 1.1 + this.audio.notes.lowBass / 500
   }
}

export default OutSphere