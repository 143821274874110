import * as THREE from 'three'
import { gsap } from 'gsap'

class Mouse {
   constructor(opt) {
      this.scene = opt.scene

      this.init()

      if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !window.matchMedia('(max-width: 1024px)').matches) {
         this.hover()
         this.mouseMove()
      }
      else {
         this.deviceOrientation()
      }
   }

   init() {
      this.target = new THREE.Vector2()
      this.mouse = new THREE.Vector2()
      this.raycaster = new THREE.Raycaster()
      this.isIntersecting = false

      this.mouseCustomCursor = { x: -100, y: -100 }
      this.pos = { x: 0, y: 0 }
      this.speed = 0.085
      this.cursor = document.querySelector('.cursor')
      this.cursorCircle = this.cursor.querySelector('.cursor-circle')
      this.isMooving = true
      this.isHovering = false
      this.isDown = false
      this.isLoaded = false
   }

   hover() {
      let hoverItems = document.querySelectorAll('.hover-items')

      let paramsItems = { left: null, top: null, width: null, height: null }

      hoverItems.forEach((el, i) => {
         el.addEventListener('mousemove', () => {

            if (!this.isDown) {
               this.isHovering = true
               clearTimeout(this.timeout)

               paramsItems.top = el.getBoundingClientRect().top
               paramsItems.left = el.getBoundingClientRect().left
               paramsItems.width = el.getBoundingClientRect().width
               paramsItems.height = el.getBoundingClientRect().height

               if (i == 1 || i == 2 || i == 10 || i == 11) this.mouseCustomCursor.x = paramsItems.left + paramsItems.width + 15
               else this.mouseCustomCursor.x = (paramsItems.left + paramsItems.width) - paramsItems.width - 15

               this.mouseCustomCursor.y = paramsItems.top + paramsItems.height / 2

               gsap.to('.circle-out', .375, { transform: 'translate(-50%, -50%) scale(0)', ease: "Power2.inOut" })
               gsap.to('.circle-in', .375, { transform: 'translate(-50%, -50%) scale(0)', ease: "Power2.inOut" })
               gsap.to('.circle-in-loading', .375, { transform: 'translate(-50%, -50%) scale(0)', ease: "Power2.inOut" })
               gsap.to('.dot-wrapper', .375, { width: '.8rem', height: '.8rem', ease: "Power2.inOut" })
               gsap.to('.dot-wrapper .dot', .375, { background: '#008eff', width: '0.25rem', height: '0.25rem', ease: "Power2.inOut" })
               gsap.to('.cursor-circle', .375, { transform: 'translate(-50%, -50%) rotate(90deg)', ease: "Power2.inOut" })
               gsap.to('.indication-wrapper', .375, { opacity: '0', transform: 'translate(-50%, -50%) rotate(-90deg) scale(.4)', ease: "Power2.inOut" })
               gsap.to('.indication-loading-wrapper', .375, { opacity: '0', transform: 'translate(-50%, -50%) rotate(-90deg) scale(.4)', ease: "Power2.inOut" })
            }
         })
         el.addEventListener('mouseleave', () => {
            if (!this.isDown) {
               this.isHovering = false
               this.hideHoverCursorAnim()
            }
         })
      })
   }

   hideHoverCursorAnim() {
      gsap.to('.circle-out', .375, { transform: 'translate(-50%, -50%) scale(1)', ease: "Power2.inOut" })
      gsap.to('.circle-in', .375, { transform: 'translate(-50%, -50%) scale(1)', ease: "Power2.inOut" })
      gsap.to('.circle-in-loading', .375, { transform: 'translate(-50%, -50%) scale(1)', ease: "Power2.inOut" })
      gsap.to('.dot-wrapper', .375, { width: '4rem', height: '4rem', ease: "Power2.inOut" })
      gsap.to('.dot-wrapper .dot', .375, { background: '#00cbff', width: '0.3rem', height: '0.3rem', ease: "Power2.inOut" })
      gsap.to('.cursor-circle', .375, { transform: 'translate(-50%, -50%) rotate(0deg)', ease: "Power2.inOut" })
   }

   mouseMove() {
      document.addEventListener('mousemove', e => {
         this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1
         this.mouse.y = - (e.clientY / window.innerHeight) * 2 + 1
         if (!this.isHovering) {
            this.mouseCustomCursor.x = e.clientX
            this.mouseCustomCursor.y = e.clientY

            if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !window.matchMedia('(max-width: 1024px)').matches && this.isLoaded) {
               clearTimeout(this.timeout)

               this.timeout = setTimeout(() => {
                  gsap.to('.dom-container', .375, { opacity: '0', ease: "Power2.out" })
                  gsap.to('.circle-in-loading svg circle', .75, { strokeDashoffset: '120rem', ease: "Power2.inOut" })
                  gsap.to('.circle-in svg circle', .75, { strokeDashoffset: '0rem', ease: "Power2.inOut" })
                  gsap.to('.circle-out svg circle', .75, { strokeDashoffset: '120rem', ease: "Power2.inOut" })
                  gsap.to('.circle-out', .375, { transform: 'translate(-50%, -50%) scale(.25)', ease: "Power2.inOut" })
                  gsap.to('.cursor-circle', .375, { transform: 'translate(-50%, -50%) rotate(90deg)', ease: "Power2.inOut" })
                  gsap.to('.dot-wrapper .dot', .75, { opacity: '0', transform: 'scale(0)', stagger: { from: 'center', each: 0.075 }, ease: "Power2.inOut" })
                  gsap.to('.indication-wrapper', .375, { opacity: '0', transform: 'translate(-50%, -50%) rotate(-90deg) scale(.4)', ease: "Power2.inOut" })
                  gsap.to('.indication-loading-wrapper', .375, { opacity: '1', transform: 'translate(-50%, -50%) rotate(-90deg) scale(1)', ease: "Power2.inOut" })
                  document.body.style.cursor = "none"
               }, 2500)
            }

            if (this.isIntersecting && this.isMooving) {
               clearTimeout(this.timeout)
               gsap.to('.circle-in-loading svg circle', .75, { strokeDashoffset: '120rem', ease: "Power2.inOut" })
               gsap.to('.circle-in svg circle', .75, { strokeDashoffset: '0rem', ease: "Power2.inOut" })
               gsap.to('.circle-out svg circle', .75, { strokeDashoffset: '120rem', ease: "Power2.inOut" })
               gsap.to('.circle-out', .375, { transform: 'translate(-50%, -50%) scale(.25)', ease: "Power2.inOut" })
               gsap.to('.cursor-circle', .375, { transform: 'translate(-50%, -50%) rotate(90deg)', ease: "Power2.inOut" })
               gsap.to('.dot-wrapper .dot', .75, { opacity: '0', transform: 'scale(0)', stagger: { from: 'center', each: 0.075 }, ease: "Power2.inOut" })
               gsap.to('.indication-wrapper', .375, { opacity: '1', transform: 'translate(-50%, -50%) rotate(-90deg) scale(1)', ease: "Power2.inOut" })
               gsap.to('.indication-loading-wrapper', .375, { opacity: '0', transform: 'translate(-50%, -50%) rotate(-90deg) scale(0.4)', ease: "Power2.inOut" })
               document.body.style.cursor = "none"
            }

            if (!this.isIntersecting && this.isMooving) {
               gsap.to('.dom-container', .375, { opacity: '1', ease: "Power2.out" })
               gsap.to('.circle-in-loading svg circle', .75, { strokeDashoffset: '120rem', ease: "Power2.inOut" })
               gsap.to('.circle-in svg circle', .75, { strokeDashoffset: '120rem', ease: "Power2.inOut" })
               gsap.to('.circle-out svg circle', .75, { strokeDashoffset: '0rem', ease: "Power2.inOut" })
               gsap.to('.circle-out', .375, { transform: 'translate(-50%, -50%) scale(1)', ease: "Power2.inOut" })
               gsap.to('.cursor-circle', .375, { transform: 'translate(-50%, -50%) rotate(0deg)', ease: "Power2.inOut" })
               gsap.to('.dot-wrapper .dot', .75, { opacity: '1', transform: 'scale(1)', stagger: { from: 'center', each: 0.075 }, ease: "Power2.inOut" })
               gsap.to('.indication-wrapper', .375, { opacity: '0', transform: 'translate(-50%, -50%) rotate(-90deg) scale(0.4)', ease: "Power2.inOut" })
               gsap.to('.indication-loading-wrapper', .375, { opacity: '0', transform: 'translate(-50%, -50%) rotate(-90deg) scale(0.4)', ease: "Power2.inOut" })
               document.body.style.cursor = "initial"
            }
         }
      })
   }

   deviceOrientation() {
      window.addEventListener('deviceorientation', (e) => {
         if (window.matchMedia('(orientation: portrait)').matches) {
            this.mouse.x = e.gamma ? e.gamma * (Math.PI / 90) : 0
            this.mouse.y = e.beta ? e.beta * (Math.PI / 90) : 0
         }
         if (window.matchMedia('(orientation: landscape)').matches) {
            this.mouse.x = e.beta ? e.beta * (Math.PI / 90) : 0
            this.mouse.y = e.gamma ? e.gamma * (Math.PI / 270) : 0
         }
      })
   }

   updateCursor() {
      const diffX = Math.round(this.mouseCustomCursor.x - this.pos.x)
      const diffY = Math.round(this.mouseCustomCursor.y - this.pos.y)

      this.pos.x += diffX * this.speed
      this.pos.y += diffY * this.speed

      const translateCursor = 'translate3d(' + this.pos.x + 'px ,' + this.pos.y + 'px, 0)'

      this.cursor.style.transform = translateCursor
   }

   update() {
      // Set smooth
      this.target.x = this.mouse.x * 0.05
      this.target.y = this.mouse.y * 0.05

      if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !window.matchMedia('(max-width: 1024px)').matches) {
         this.updateCursor()
      }
   }
}

export default Mouse