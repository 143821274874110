import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass.js";
import vertexDisplacement from "../glsl/displacement/vertex.vert";
import fragmentDisplacement from "../glsl/displacement/fragment.frag";

class Scene {
  constructor(opt) {
    this.canvas = opt.canvas;

    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.init();
    this.resize();
  }

  init() {
    this.scene = new THREE.Scene();
    this.fog = new THREE.Fog(0x000000, 2, 8);
    this.scene.fog = this.fog;
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.sizes.width / this.sizes.height,
      0.01,
      8
    );
    this.camera.position.set(0, 0, 3);
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      powerPreference: "high-performance",
      antialias: false,
    });
    this.renderer.gammaFactor = 2.2;
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1;
    this.renderer.stencil = false;
    this.renderer.preserveDrawingBuffer = false;
    this.renderer.depth = false;
    this.renderer.premultipliedAlpha = false;
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(this.sizes.width, this.sizes.height);

    this.clock = new THREE.Clock();

    this.scene.add(this.camera);

    this.renderScene = new RenderPass(this.scene, this.camera);

    this.unrealBloomPass = new UnrealBloomPass();
    this.unrealBloomPass.strength = 2.5;
    this.unrealBloomPass.radius = 0;
    this.unrealBloomPass.threshold = 0.1;

    this.afterimagePass = new AfterimagePass();
    this.afterimagePass.uniforms.damp.value = 0.65;

    this.composer = new EffectComposer(this.renderer);
    this.composer.setSize(this.sizes.width, this.sizes.height);
    this.composer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    this.composer.addPass(this.renderScene);
    this.composer.addPass(this.afterimagePass);
    this.composer.addPass(this.unrealBloomPass);

    this.displacementShader = {
      uniforms: {
        uTime: { value: 0 },
        tDiffuse: { value: null },
        uProgress: { value: 0 },
        uDisplacement: { value: null },
        uDispFactor: { value: 0 },
        uEffectFactor: { value: 0.05 },
        uColor: { value: new THREE.Color(0x0025ff) },
      },
      vertexShader: vertexDisplacement,
      fragmentShader: fragmentDisplacement,
    };

    this.displacementPass = new ShaderPass(this.displacementShader);
    this.composer.addPass(this.displacementPass);
  }

  resize() {
    window.addEventListener("resize", () => {
      // Update sizes
      this.sizes.width = window.innerWidth;
      this.sizes.height = window.innerHeight;

      // Update camera
      this.camera.aspect = this.sizes.width / this.sizes.height;
      this.camera.updateProjectionMatrix();

      // Update postprocessing
      this.composer.setSize(this.sizes.width, this.sizes.height);
      this.composer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      // Update renderer
      this.renderer.setSize(this.sizes.width, this.sizes.height);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });
  }
}

export default Scene;
