import * as THREE from 'three'
import vertex from '../glsl/innerSphere/vertex.vert'
import fragment from '../glsl/innerSphere/fragment.frag'

class InnerSphere {
   constructor(opt) {
      this.scene = opt.scene
      this.audio = opt.audio

      this.init()
   }

   init() {
      this.innerSphereGeometry = new THREE.SphereBufferGeometry(1, 120, 120)
      this.innerSphereMaterial = new THREE.ShaderMaterial({
         vertexShader: vertex,
         fragmentShader: fragment,
         uniforms: {
            uTime: { value: 0 },
            uColor: { value: new THREE.Color(0x008EFF) },
            uColor2: { value: new THREE.Color(0x00CBFF) },
            uColor3: { value: new THREE.Color(0x52FBDD) },

            uRepeatSpeed: { value: 0 },
            uDeformedIntensity: { value: 20 },

            uAlpha: { value: 0 },
            uAlphaLoad: { value: 2 },
            uSize: { value: 1 },
         },
         transparent: true,
         depthWrite: false,
         blending: THREE.AdditiveBlending,
      })

      this.innerSphereMesh = new THREE.Points(this.innerSphereGeometry, this.innerSphereMaterial)
      this.innerSphereMesh.scale.set(0.5, 0.5, 0.5)
      // this.innerSphereMesh.frustumCulled = false
   }

   update(time) {
      this.innerSphereMaterial.uniforms.uTime.value = time
      this.innerSphereMaterial.uniforms.uDeformedIntensity.value = 20 - this.audio.notes.bass / 34
      this.innerSphereMaterial.uniforms.uRepeatSpeed.value = this.audio.notes.bass / 250

      this.innerSphereMesh.rotation.y = - time / 2
      this.innerSphereMesh.scale.x = .5 + this.audio.notes.bass / 1300
      this.innerSphereMesh.scale.y = .5 + this.audio.notes.bass / 1300
      this.innerSphereMesh.scale.z = .5 + this.audio.notes.bass / 1300
   }
}

export default InnerSphere